import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as apiUtils from '../../utils/apiUtils';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import './RecordingBanner.css';

const RecordingBanner = () => {
    const navigate = useNavigate();
    const checkCreateNoteIsAllowed = async () => {
        try {
            const data = await apiUtils.get('/api/notes/check_create_note_allow');
            return data.can_create
        } catch (error) {
            console.error('getUserSettings.error', error)
        }
    }
    const createNote = async () => {
        await apiUtils.refreshToken()
        const response = await checkCreateNoteIsAllowed()
        if (response) {
            navigate('/create-note');
        } else {
            toast.error("You have exhausted the monthly session limit.");
        }
    }
    return (
        <div className="recording-banner" onClick={createNote}>
            {/* <i className="fas fa-microphone recording-icon"></i> */}
            {/* <p className="recording-text">Create Note...</p> */}
            <Button className="button-container-client-profile" >Create Note</Button>
        </div>
    );
};

export default RecordingBanner;
