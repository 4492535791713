import useToken from '../../utils/useToken';
import useProfile from '../../utils/useProfile';

import React, { useState } from 'react';
import './LoginPage.css';
import * as apiUtils from '../../utils/apiUtils';
import { useNavigate } from 'react-router-dom';
const LoginPage = () => {
    const navigate = useNavigate();
    const { setToken, saveRefreshToken } = useToken();
    const { setProfile } = useProfile();
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!credentials.email || !credentials.password) {
            setError('Please enter both email and password');
            return;
        }
        const response = await loginUser(credentials);
        console.log('API Response:', response);
        if (response.access) {
            setToken(response.access);
            setProfile(JSON.stringify(response.user))
            saveRefreshToken(response.refresh)
            if (response.user.user_setting.subscription)
                window.location.href = '/dashboard'
            else
                window.location.href = '/stripe-checkout'
            setError('');
        } else {
            if (response?.non_field_errors?.length > 0) {
                // show first error message from list
                const errorMessage = response.non_field_errors.at(0);
                setError(errorMessage);
            } else {
                setError('Email or Password is incorrect.');
            }
        }
    };
    async function loginUser(credentials) {
        try {
            const data = await apiUtils.post('/api/auth/login/', credentials);
            return data
        } catch (error) {
            console.error('API Error:', error);
            return error
        }
    }
    return (
        <div className="login-container">
            <h1 onClick={()=>{navigate('/')}} className="logo cura-font-bold">Cura</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={credentials.email}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={credentials.password}
                    onChange={handleChange}
                />
                {error && <div className="error-message">{error}</div>}
                <button className="login-btn" type="submit">Login</button>
                <div className="links">
                    <a href="/forgot-password">Forgot Password?</a>
                    <a href="/register">Sign Up</a>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;
