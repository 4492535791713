import React from 'react';
import { Row, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import * as apiUtils from '../../utils/apiUtils';
import { toast } from 'react-toastify';
const CardDetails = ({ card }) => {
  const navigate = useNavigate();
  const removeCard = async ()=>{
    try {
      const data = await apiUtils.post('/api/user/remove_default_card');
      navigate('/')
      toast.success("Card removed successfully");
    } catch (error) {
      console.error('removeCard.error', error)
    }
  }
  return (
    <Row style={styles.container}>
      <Row>
        <div style={{ textAlign: 'left', fontWeight: '900' }}>Saved Card Details</div>
      </Row>
      <Row xs='12'>
        <div>{`Brand: ${card.brand}`}</div>
        <div>{`Card: **** **** **** ${card.last4}`}</div>
        <div>{`Exp: ${card.exp_month}/${card.exp_year}`}</div>
      </Row>
      
      <Row><br></br><Button onClick={removeCard} className='subscribe-btn'>Remove Card</Button></Row>
    </Row>
  );
};

// Function to get brand logo based on the brand name
const getBrandLogo = (brand) => {
  // Replace 'visa', 'mastercard', 'amex', 'discover', etc. with actual image URLs
  switch (brand) {
    case 'visa':
      return 'visa_logo.png';
    case 'mastercard':
      return 'mastercard_logo.png';
    case 'amex':
      return 'amex_logo.png';
    case 'discover':
      return 'discover_logo.png';
    default:
      return 'default_logo.png';
  }
};

const styles = {
  container: {
    // width: '100%',
    backgroundColor: '#004080',
    display: 'flex',
    justifyContent: 'center',
    margin: 10,
    borderRadius: '5px',
    padding: '15px'
  },
  card: {
    backgroundColor: '#004080',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    width: 50,
    marginRight: 10,
  },
};

export default CardDetails;
