import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import * as apiUtils from '../../utils/apiUtils';
import React, { useState } from 'react';
import './SignupPage.css';

const SignupPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password1: '',
        password2: '',
        first_name: '',
        last_name: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Clear error on input change
        if (errors[e.target.name]) {
            setErrors({ ...errors, [e.target.name]: '' });
        }
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.first_name.trim()) newErrors.first_name = 'First Name is required';
        if (!formData.last_name.trim()) newErrors.last_name = 'Last Name is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!formData.password1) newErrors.password1 = 'Password is required';
        if (formData.password1 !== formData.password2) newErrors.password2 = 'Passwords do not match';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                const data = await apiUtils.post('/api/auth/registration/', formData);
                console.log('handleSubmit.data', data);
                navigate("/login");
                toast.success("Registration successful! Verification e-mail sent.");
            } catch (error) {
                console.log('handleSubmit.error', error)
                const backendErrors = processErrors(error);
                setErrors(backendErrors);
            } finally {
                setIsLoading(false);
            }
        }
    };
    const processErrors = (errorObject) => {
        let processedErrors = {};
        for (const key in errorObject) {
            // Assuming each field has only one error message
            processedErrors[key] = errorObject[key][0];
        }
        return processedErrors;
    };
    return (
        <div className="signup-container">
            <h1 onClick={()=>{navigate('/')}} className="logo cura-font-bold">Cura</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={handleChange}
                />
                {errors.first_name && <div className="error">{errors.first_name}</div>}

                <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={handleChange}
                />
                {errors.last_name && <div className="error">{errors.last_name}</div>}

                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <div className="error">{errors.email}</div>}

                <input
                    type="password"
                    name="password1"
                    placeholder="Password"
                    value={formData.password1}
                    onChange={handleChange}
                />
                {errors.password1 && <div className="error">{errors.password1}</div>}

                <input
                    type="password"
                    name="password2"
                    placeholder="Confirm Password"
                    value={formData.password2}
                    onChange={handleChange}
                />
                {errors.password2 && <div className="error">{errors.password2}</div>}
                <button className='sign-up-button' type="submit" disabled={isLoading}>
                    {isLoading ? 'Registering...' : 'Register'}
                </button>
                <div className="links">
                    <a href="/forgot-password">Forgot Password?</a>
                    <a href="/login">Login</a>
                    {/* <a href="/privacy-policy">Privacy Policy</a> */}
                </div>
            </form>
        </div>
    );
};

export default SignupPage;
