import React from 'react';
import { useNavigate } from "react-router-dom";
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    const navigate = useNavigate();
    return (
        <div className="privacy-container">
            <section>
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date:</strong> November 19, 2023</p>
                <p><strong>Last Updated:</strong> November 19, 2023</p>

                <h2>1. Introduction</h2>
                <p>Cura & Byway LLC ("Cura") provides software services that automate note taking for mental health professionals such as therapists. This allows therapists to save time on administrative tasks. Cura may also offer treatment recommendations to therapists based on analysis of historical client notes.</p>
                <p>This Privacy Policy informs you about the personal information Cura collects, how it is used, and your rights regarding that information. By using Cura's services or otherwise providing personal information to us, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>

                <h2>2. Information Collected by Cura</h2>
                <p>Cura collects the following categories of personal information:</p>
                <ul>
                    <li>Identifiers: Name, email</li>
                    <li>Financial information: Credit card numbers</li>
                    <li>Protected Health Information (PHI): Client names and details entered by users, protected under HIPAA</li>
                    <li>Usage data: Information on use of Cura's services</li>
                </ul>
                <p>Cura collects this information directly from users when they provide it to sign up, use the services, or make payments. Usage data is also collected automatically through use of the service.</p>

                <h2>3. Use of Personal Information</h2>
                <p>Cura uses the personal information collected for the following purposes:</p>
                <ul>
                    <li>To provide Cura's services to users</li>
                    <li>To respond to user inquiries and requests</li>
                    <li>To improve Cura's products and services</li>
                </ul>

                <h2>4. Disclosure of Personal Information</h2>
                <p>Cura does not sell personal information to third parties under any circumstances. Cura shares only personal information necessary to provide its services with third party service providers Microsoft Azure and Google Business Suite under signed BAAs. This information is encrypted. No other sharing or disclosure of personal information to third parties occurs.</p>

                <h2>5. User Rights</h2>
                <p>Users have the following rights regarding their personal information:</p>
                <ul>
                    <li>Right to access personal information</li>
                    <li>Right to request deletion of personal information</li>
                    <li>Clients can request access to their information through their user</li>
                </ul>
                <p>To exercise these rights, contact Cura at <a href="mailto:support@curanote.co">support@curanote.co</a>.</p>

                <h2>6. Data Security</h2>
                <p>Cura implements reasonable security practices to help protect personal information. However, no system is completely secure and we cannot guarantee the security of information.</p>

                <h2>7. Changes to the Privacy Policy</h2>
                <p>Cura may update this Privacy Policy from time to time. We will notify users of material changes either through the services or by other means.</p>

                <h2>8. Contact Information</h2>
                <p>If you have questions about Cura's privacy practices, contact us at <a href="mailto:support@curanote.co">support@curanote.co</a>.</p>

                <h2>9. Privacy Notice for California Residents</h2>
                <p>This section provides additional details about Cura's privacy practices with respect to California residents, as required under the California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA).</p>
                <p><strong>Effective Date:</strong> November 19, 2023</p>
                <p>Categories of personal information collected:</p>
                <ul>
                    <li>Identifiers</li>
                    <li>Financial information</li>
                    <li>Protected Health Information</li>
                    <li>Usage data</li>
                </ul>
                <p>Purposes for collecting/using personal information:</p>
                <ul>
                    <li>To provide Cura's services</li>
                    <li>To respond to inquiries and requests</li>
                    <li>To improve Cura's services</li>
                </ul>
                <p>Cura does not sell or share personal information with third parties, except to service providers under signed BAAs as described above.</p>
                <p>California residents have the right to request access to or deletion of their personal information, as well as other rights under the CCPA/CPRA. To exercise these rights, contact Cura at <a href="mailto:support@curanote.co">support@curanote.co</a>.</p>
            </section>
            <button className='btn-privacy-policy' onClick={() => navigate(-1)}>Back</button>
        </div>
    );
}

export default PrivacyPolicy;
