import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import * as apiUtils from '../../utils/apiUtils';
import { Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

function EmailValidation() {
  const navigate = useNavigate();
  const pathParams = useParams();
  const [isVerified, setIsVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const onVerificationFailed = (errorMessage) => {
    setIsVerified(false);
    setIsLinkExpired(true)
    setVerificationMessage(errorMessage);
  }
  const verifyEmail = async (key) => {
    try {
      const response = await apiUtils.post('api/auth/registration/verify-email/', { key });
      if (response.detail === 'ok') {
        setIsVerified(true);
        navigate("/login");
        toast.success("Email verified, please login.");
      } else {
        const errorData = await response.json();
        await onVerificationFailed(errorData.message)
      }
    } catch (error) {
      console.error('Error verifying email:', error.detail);
      await onVerificationFailed(error.detail)
    }
  };
  useEffect(() => {
    if (pathParams.token) {
      verifyEmail(pathParams.token);
    } else {
      setIsLinkExpired(true);
      setVerificationMessage('Verification link not found or expired.');
    }
  }, []);

  const handleRetry = async (e) => {
    if (email) {
      const response = await apiUtils.post('/api/auth/registration/resend-email/', { email });
      setVerificationMessage('Email sent, please check your inbox.');
    } else {
      console.log('handleRetry.error', 'enter email')
    }
  }
  return (
    <div>
      <h2>Email Verification</h2>
      {isVerified ? (
        <div>
          <p>Email verified successfully!</p>
          <p>{verificationMessage}</p>
        </div>
      ) : isLinkExpired ? (
        <div>
          <p>{verificationMessage}</p>
          <input type="text"
            onChange={e => setEmail(e.target.value)}
          ></input>
          <Button onClick={handleRetry}>Retry</Button>
        </div>
      ) : (
        <div>
          <p>Verifying email...</p>
        </div>
      )}
    </div>
  );
}

export default EmailValidation;
