import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    // const tokenString = localStorage.getItem('token');
    // const userToken = JSON.parse(tokenString);
    // return userToken?.token
    return localStorage.getItem('token');
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const saveRefreshToken = refreshToken => {
    localStorage.setItem('refreshToken', refreshToken);
  };
  const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
  };

  return {
    setToken: saveToken,
    token,
    saveRefreshToken,
    getRefreshToken
  }
}