import React, { useState } from 'react';
import Menu from '../Menu'
import ManagePayments from '../ManagePayments';
import PaymentComponent from '../PaymentComponent'

const CheckoutForm = () => {
    const [planId, setPlanId] = useState('');

    return (
        <>
            <Menu isPlanActive={false} />
            <div className='notes-list'>
                {!planId && <ManagePayments setPlanId={setPlanId} displaySubscribeBtn={true}/>}
                {planId && <PaymentComponent planId={planId} />}
            </div>
        </>
    );
};

export default CheckoutForm;