import React, { useState } from 'react';
import * as apiUtils from '../../utils/apiUtils';
import { useNavigate } from "react-router-dom";
import './ForgotPasswordPage.css';
import Loader from '../Loader';

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        try {
            await sendResetEmail(email);
            setEmailSent(true);
        } catch (error) {
            console.error('Error sending email:', error);
        } finally {
            setLoading(false)
        }
    };
    const sendResetEmail = async (email) => {
        try {
            const data = await apiUtils.post('/api/auth/password/reset/', {email});
            return data
        } catch (error) {
            console.error('API Error:', error);
            return error
        }
    };

    if (emailSent) {
        return (
            <div className="forgot-password-container">
                <h1  className="logo cura-font-bold">Cura</h1>
                <p>A link to reset your password has been sent to your email address.</p>
                <a className="link" href="/login">Login</a>
            </div>
        );
    }

    return (
        <>
        {loading ? (
        <Loader />
      ) : (<div className="forgot-password-container">
      <h1 onClick={()=>{navigate('/')}} className="logo cura-font-bold">Cura</h1>
      <form onSubmit={handleSubmit}>
          <input 
              type="email"
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
          />
          <button className="forgot-password-button" type="submit">Reset Password</button>
      </form>
      <a href="/login">Login</a>
  </div>)}
        </>
    );
};

export default ForgotPasswordPage;
