// import React from 'react';
// import './NotesList.css'
// import NotesListRow from './NotesListRow'
// function NoteList({ notes }) {
//   return (
//     <div className="note-list-container">
//       <h2>Notes</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Client Name</th>
//             <th>Title</th>
//             <th>Status</th>
//             <th>Short Description</th>
//             <th>Created Time</th>
//           </tr>
//         </thead>
//         <tbody>
//           {notes.map((note, index) => (
//             <NotesListRow clientName={note.clientName} date={note.createdTime} summary={note?.shortDescription??''} />
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default NoteList;




import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotesList.css';

const NotesList = ({ notes }) => {
  const navigate = useNavigate();
  return (
    <div className="notes-list">
      {notes.length > 0 ? <div className="notes-header">Notes</div> : 'No notes available'}
      <ul className="notes">
        {notes.map((note, index) => (
          <li onClick={()=>{navigate(`/summary/${note.id}`)}} key={index} className={`note ${note.status ? 'processed': 'processing'}`}>
            <div className="row1">
              <div className="client-name">{note?.client_data?.first_name??''}</div>
              {note.status && <div className="date">{formatDate(note.created_at)}</div>}
              {!note.status && <div className="date">Processing...</div>}
            </div>
            <div className="row2">
              {formatSummary(note.summary)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Helper function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

// Helper function to format the summary to 4 lines
const formatSummary = (summary) => {
  const lines = summary?.split('\n').slice(0, 4);
  return lines?.join('\n');
};

export default NotesList;
