import useToken from '../../utils/useToken';
import useProfile from '../../utils/useProfile';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const LogoutMessage = () => {
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
    };
    return (
        <div className="login-container">
            <h1 onClick={()=>{navigate('/')}} className="logo cura-font-bold">Cura</h1>
            <div>You have successfully logged out.</div>
            <p>Thank you for visiting. We hope to see you again soon!</p>

            <form onSubmit={handleSubmit}>
            </form>
        </div>
    );
};

export default LogoutMessage;