import React from 'react';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
const WarningMessage = () => {
    return (
      <div style={{ position: 'fixed', bottom: 0, width: '360px', zIndex: 1000 }}>
        <Alert color="warning" className="text-center">
          <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
          Session notes are being generated. We do not store or save any audio of this session.
        </Alert>
      </div>
    );
  };
export default WarningMessage;