import React, { useEffect, useState } from 'react';
import Menu from '../Menu'
import SummaryInstructions from '../SummaryInstructions';
import ManagePayments from '../ManagePayments';
import NotePreviewer from '../NotePreviewer'
import * as apiUtils from '../../utils/apiUtils';
import useProfile from '../../utils/useProfile';
import SummaryScreen from '../SummaryScreen';
import PaymentComponent from '../PaymentComponent'
import CardDetails from '../CardDetails';
import './SettingsPage.css'
function SettingsPage() {
  const [summary, setSummary] = useState();
  const [planId, setPlanId] = useState('');
  const { profile } = useProfile();
  const [userSetting, setUserSetting] = useState();
  const getUserSettings = async () => {
    try {
      const data = await apiUtils.get('/api/user/user_setting');
      console.log('API Response:', data);
      setUserSetting(data)
      setSummary(data?.summary_instructions??'')
    } catch (error) {
      console.error('getUserSettings.error', error)
    }
  }
  useEffect(() => {
    getUserSettings()
  }, []);


  return (
    <div >
      <Menu isPlanActive={true}/>
      <div className="settings-page">
        
        <SummaryScreen profile={profile} userSetting={userSetting} summary={summary} setSummary={setSummary} />
        <NotePreviewer  summary={summary} />
        {/* {userSetting?.customer?.default_card && <CardDetails card={userSetting?.customer?.default_card} />} */}
        {/* {!planId && <ManagePayments setPlanId={setPlanId} profile={profile} userSetting={userSetting} displaySubscribeBtn={false}/>} */}
        {/* {planId && <PaymentComponent planId={planId} />} */}
      </div>
    </div>
  );
}

export default SettingsPage;
