import { useState } from 'react';

export default function useProfile() {
  const getProfile = () => {
    let profileObj = {}
    try {
      profileObj = JSON.parse(localStorage.getItem('profile'))
    } catch (error) {
      console.log('getProfile.error', error)
    }
    return profileObj;
  };

  const [profile, setProfile] = useState(getProfile());

  const saveProfile = userProfile => {
    localStorage.setItem('profile', userProfile);
    setProfile(userProfile);
  };

  return {
    setProfile: saveProfile,
    profile
  }
}