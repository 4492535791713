import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as apiUtils from '../../utils/apiUtils';
import Menu from '../Menu';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faCopy, faPencilAlt, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Loader from '../Loader';
import './SummaryComponent.css';

const SummaryComponent = () => {
  const navigate = useNavigate();
  const { summaryId } = useParams();
  const [summary, setSummary] = useState({
    quick_summary: '',
    summary: '',
    updated_at: '',
  });
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientID, setSelectedClientID] = useState(null);

  useEffect(() => {
    const fetchSummaryAndClients = async () => {
      try {
        const summaryData = await apiUtils.get(`/api/notes/${summaryId}`);

        setSelectedClient(`${summaryData?.client_data?.first_name?? 'Unassigned'} ${summaryData?.client_data?.last_name??''}`)
        setSelectedClientID(summaryData.client)
        setSummary({
          quick_summary: summaryData.quick_summary,
          summary: summaryData.summary,
          updated_at: summaryData.updated_at,
        });

        const clientsData = await apiUtils.get('/api/notes/clients');
        setClients(clientsData.results);
      } catch (error) {
        console.error('Fetching summary or clients failed:', error);
      } finally {
        setLoading(false);
      }
    };

    if (summaryId) {
      fetchSummaryAndClients();
    }
  }, [summaryId]);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleClientChange = (client) => {
    setSelectedClient(`${client.first_name} ${client.last_name}`);
    setSelectedClientID(client.id)
  };

  const handleDeleteNote = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete the note?');
    if (isConfirmed) {
      await deleteNote(summaryId)
      toast.success("Note deleted successfully");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  const lastEdited = summary.updated_at ? `Last edited ${formatDate(summary.updated_at)}` : '';

  const deleteNote = async (noteID) =>{
    try {
      const data = await apiUtils.del(`/api/notes/${noteID}`);
      console.log('API Response:', data);
      navigate('/dashboard')
    } catch (error) {
      console.log('deleteNote.error', error)
    }
  }
  const handleSave = async () => {
    try {
      const data = await apiUtils.patch(`/api/notes/${summaryId}`, {
        client: selectedClientID
      });
      console.log('API Response:', data);
      if (data.id) {
        // togglePopup()
        navigate('/dashboard')
      }
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <>
      <Menu isPlanActive={true}/>
      {loading ? (
        <Loader />
      ) : (
        <div className="summary-container">
          <div className="summary-header">
            <FontAwesomeIcon style={{ marginRight: '15px' }} icon={editMode ? faCheck : faPencilAlt} onClick={handleEditToggle} />
            <FontAwesomeIcon style={{ marginRight: '15px' }} icon={faTrash} onClick={handleDeleteNote} className="delete-icon" />
            <CopyToClipboard text={summary.summary} onCopy={() => alert('Summary copied to clipboard!')}>
              <FontAwesomeIcon icon={faCopy} />
            </CopyToClipboard>
            <UncontrolledDropdown className="client-select-dropdown" direction="down">
              <DropdownToggle caret color="primary">
                {selectedClient || 'Select Client'}
              </DropdownToggle>
              {editMode && <DropdownMenu>
                {clients.map((client) => (
                  <DropdownItem key={client.id} onClick={() => handleClientChange(client)}>
                    {client.first_name} {client.last_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>}
            </UncontrolledDropdown>
          </div>
          <div className="summary-date">{lastEdited}</div>
          <h2 className="summary-heading">Quick Summary</h2>
          <p>{summary.quick_summary}</p>
          <h2 className="summary-heading">Complete Summary</h2>
          <p>{summary.summary}</p>
          {editMode && <button className="btn-class" type="button" onClick={handleSave}>
            Save
          </button>}
        </div>
      )}
    </>
  );
};

export default SummaryComponent;
