import React, { useState,useEffect } from 'react';
import * as apiUtils from '../../utils/apiUtils';
import './SummaryScreen.css';

const SummaryScreen = (props) => {

  
  // useEffect(() => {
  //   props.setSummary(props?.userSetting?.summary_instructions??'')
  // }, [props]);

    // let summary = props?.userSetting?.summary_instructions
  const handleSave = async () => {
    try {
        const data = await apiUtils.put('/api/user/user_setting', {
            summary_instructions: props.summary
        });
        console.log('handleSave.data', data)
      } catch (error) {
        console.error('handleSave.error', error)
      }
    console.log('Summary saved:', props.summary);
  };

  const handleRestore = () => {
    props.setSummary(props?.userSetting?.default_summary_instructions??'')
  }

  return (
    <div className="container">
      <div className="profileName">Note settings</div>
      <span>If you'd like some tips and tricks on how to help Cura create notes for your needs, <a href="https://docs.google.com/document/d/1vYfweuNEgb6c3pb6KXKeVUp0C5xZzZ3daAeSlxpk0Gs/edit" target="_blank" rel="noopener noreferrer">see here</a>.</span>
      <br></br>
      <br></br>
      <div>
        <p>
          Enter brief guidelines here to customize your session summaries. Specify key points or themes you want emphasized or even suggest formatting for tailored notes. It may take a few attempts, but the results will be worth it.
        </p>
      </div>
      <textarea
        className="textArea"
        placeholder="Enter summary instructions here"
        // defaultValue={props.summary}
        value={props.summary}
        rows="4"
        onChange={(e) => 
          props.setSummary(e.target.value)
        }
      />
      <div className="button-settings">
        <button className="save-btn" onClick={handleSave}>Save</button>
        <button className="restore-btn" onClick={handleRestore}>Restore Default</button>
      </div>
    </div>
  );
};

export default SummaryScreen;
