import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL_CURA
// const baseURL = 'http://20.127.153.3/'
// const baseURL = 'https://dev-api.curanote.co'
// Create an instance of axios with a custom config
const token = localStorage.getItem('token')
const axiosInstance = axios.create({
  baseURL,
  timeout: 100000, // Adjust the timeout as needed
  headers: {
    'Content-Type': 'application/json',
    ...(token ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {})
  },
});
// Function to refresh token
export const refreshToken = async () => {
  // try {
   // Implement your logic to refresh the token
  const response = await axiosInstance.post('/api/auth/token/refresh/', {refresh: localStorage.getItem('refreshToken')});
  localStorage.setItem('token',response.data.access)
  // localStorage.setItem('refreshToken',response.data.refreshToken)
  // } catch (error) {
  //   console.log('error', error)
  // }
};

// Request interceptor to attach authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      // originalRequest._retry = true;
      try {
        await refreshToken();
        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle token refresh error
        console.error('Token refresh failed:', refreshError);
        localStorage.clear()
        window.location.href = '/'
        // Redirect to login or handle as needed
      }
    }
    return Promise.reject(error);
  }
);

// Function to make a GET request
export const get = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to make a POST request
export const post = async (url, data = {}, navigate,setToken) => {
  try {
    console.log('baseURL', baseURL)
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    // if(error?.response?.status === 401) {
    //   setToken('')
    //   navigate('/login');
    // }
    throw error?.response?.data ?? error;
  }
};

// Function to make a PUT request
export const put = async (url, data = {}) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const patch = async (url, data = {}) => {
  try {
    const response = await axiosInstance.patch(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to make a DELETE request
export const del = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
