import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
import Login from '../Login/Login';
import LogoutMessage from '../LogoutMessage'
import Preferences from '../Preferences/Preferences';
import Register from '../Register'
import EmailValidation from '../EmailValidation'
import useToken from '../../utils/useToken';
import ForgotPassword from '../ForgotPassword';
import Dashboard from '../Dashboard/Dashboard';
import ClientInfo from '../ClientInfo';
import SettingsPage from '../SettingsPage';
import Account from '../AccountPage';
import StripeCheckout from '../StripeCheckout'
import NotFound from '../Common/NotFound'
import CreateNoteWithAudio from '../CreateNoteWithAudio';
import PrivacyPolicy from '../PrivacyPolicy'
import ClientList from '../ClientList';
import LandingPage from '../LandingPage'
import SummaryComponent from '../SummaryComponent'
import ResetPassword from '../ResetPassword'
import NotePreviewer from '../NotePreviewer'
import DefaultTranscript from '../DefaultTranscript'

function App() {
  const { token } = useToken();

  return (
    <div className="wrapper">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

          {!token && <>
            <Route path="/logout" element={<LogoutMessage />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/email-validation/:token" element={<EmailValidation />}></Route>
            <Route path="/password-reset/confirm/:userID/:token" element={<ResetPassword />}></Route>
            <Route path="/" element={<LandingPage />}></Route>
          </>}
          {/* Protected routes */}
          {token ?
            <>
              <Route path="/stripe-checkout" element={<StripeCheckout />}></Route>
              <Route path="/preferences" element={<Preferences />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/" element={<Dashboard />}></Route>
              <Route path="/client-info" element={<ClientInfo />}></Route>
              <Route path="/clients" element={<ClientList />}></Route>
              <Route path="/settings" element={<SettingsPage />}></Route>
              <Route path="/account" element={<Account />}></Route>
              <Route path="/create-note" element={<CreateNoteWithAudio />}></Route>
              <Route path="/note-previewer" element={<NotePreviewer />}></Route>
              <Route path="/default-transcript" element={<DefaultTranscript />}></Route>
              <Route path="/summary/:summaryId" element={<SummaryComponent />} />
              <Route path="*" element={<NotFound />} />
            </> : <Route path="*" element={<Navigate to="/" />}></Route>}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;