import React, { useEffect, useState } from 'react';
import * as apiUtils from '../../utils/apiUtils';
import Loader from '../Loader';
import Menu from '../Menu'

const DefaultTranscript = () => {
  const [transcript, setTranscript] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTranscript = async () => {
    try {
      setLoading(true)
      const data = await apiUtils.get('/api/user/get_default_transcript');
      console.log('API Response:', data);
      // Convert newline characters to <br> tags
      const textWithHtml = data?.default_transcript?.replace(/\n/g, "<br>");
      setTranscript(textWithHtml)
    } catch (error) {
      console.error('getTranscript.error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTranscript()
  }, []);

  return (
    <>
      <Menu isPlanActive={true} />
      {loading ? (
        <Loader />
      ) : (<div className='notes-list' dangerouslySetInnerHTML={{ __html: transcript }} />)}
    </>
  );
};

export default DefaultTranscript;
