import React from 'react';
import * as apiUtils from '../../utils/apiUtils';
import Menu from '../Menu'
import AudioRecorder from './AudioRecorder'
import WarningMessage from '../WarningMessage'
import './CreateNoteWithAudio.css'

function CreateNoteWithAudio() {
  return (
    <><Menu isPlanActive={true}/>
      <div className="create-note-container">
        <h2>Create Note with Audio</h2>
        <AudioRecorder />
        <WarningMessage />
      </div>
    </>
  );
}

export default CreateNoteWithAudio;
