import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '../../utils/useToken';
import useProfile from '../../utils/useProfile';
import { Row, Col } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import './Menu.css';
function Menu({ isPlanActive }) {
  const navigate = useNavigate();
  const { setToken } = useToken();
  const { setProfile } = useProfile();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null); 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    // Add event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);
  const handleSettingMenuClick = () => {
    navigate('/settings');
  }
  const handleDashboardMenuClick = () => {
    navigate('/dashboard');
  }
  const handlePrivacyClick = () => {
    window.open(
      'https://docs.google.com/document/d/1bxBhcFrF_aIPaMD87HmBWIrN6S4Ed62MeoSrhl67vdU/edit?usp=sharing',
      '_blank'
    );
  }
  const handleContactClick = () => {
    window.open("mailto:hello@curanote.co", "_blank");
  }
  const handleAccountPageClick = () =>{
    navigate('/account');
  }

  const handleLogout = () => {
    setToken('')
    setProfile('')
    window.location.href = '/logout'
  }
  const menuItems = []

  if (isPlanActive) {
    menuItems.push({ id: 'Dashboard', label: 'Dashboard', icon: '🏠' })
    menuItems.push({ id: 'Settings', label: 'Settings', icon: '⚙️' })
    menuItems.push({ id: 'Account', label: 'Account', icon: '⚙️' })
  }
  menuItems.push({ id: 'PrivacyPolicy', label: 'Privacy and Policy', icon: '🔒' })
  menuItems.push({ id: 'Contact', label: 'Contact', icon: '🚪' })
  menuItems.push({ id: 'Logout', label: 'Logout', icon: '🚪' })
  


  const MenuItem = ({ label, icon, isActive, onClick }) => {
    const activeClass = isActive ? 'menu-item--active' : '';

    return (
      <div className={`menu-item ${activeClass}`} onClick={onClick}>
        {/* {icon && <span className="menu-item__icon">{icon}</span>} */}
        <span className="menu-item__label">{label}</span>
      </div>
    );
  };
  const [activeItem, setActiveItem] = useState('Dashboard');
  return (
    <div className="menu-container" style={{ position: 'relative' }} ref={menuRef}>

      <Row xs="12" className="menu-icon" >
        <Col xs="10" style={{ display: 'flex' }}>
          <div className="menu-logo" onClick={() => { isPlanActive && navigate('/dashboard') }}>Cura</div>
        </Col>
        <Col xs="2">
          <button className="menu-btn" onClick={toggleMenu}>
            <i className="fas fa-ellipsis-v"></i>
          </button>
          {/* <button onClick={toggleMenu}>
            <FiMoreVertical />
          </button> */}
        </Col>
      </Row>
      {isMenuOpen && (
        <div
          className="menu-dropdown"
          onClick={closeMenu}
          style={{
            position: 'absolute',
            top: '100%',
            right: 0,
            // backgroundColor: '#fff',
            border: '1px solid #ccc',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 100,
          }}
        >
          <div className="menu">
            {menuItems.map(item => (
              <MenuItem
                key={item.id}
                label={item.label}
                icon={item.icon}
                onClick={() => {
                  setActiveItem(item.id)
                  switch (item.id) {
                    case 'Dashboard':
                      handleDashboardMenuClick()
                      break;
                    case 'Settings':
                      handleSettingMenuClick()
                      break;
                    case 'PrivacyPolicy':
                      handlePrivacyClick()
                      break;
                    case 'Account':
                      handleAccountPageClick()
                      break;
                    case 'Contact':
                      handleContactClick()
                      break;
                    case 'Logout':
                      handleLogout()
                      break;
                    default:
                      break;
                  }
                }
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
