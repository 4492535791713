import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
const LandingPage = () => {
    const navigate = useNavigate();
    const handleSignupBtnClick = ()=>{
        navigate('/register')
    }
    return (
        <>

            <header class="page-header">
                <div class="container">
                    <nav>
                        <a class="landing-page-link">
                            <h1>Cura</h1>
                        </a>
                        <table style={{ textAlign: 'left' }}>
                            <tr>
                                <td style={{ paddingRight: '40px', }}><a style={{ color: '#FFFFFF' }} href="/login"><b>Login</b></a></td>
                                <td><button class="button_header button" onClick={handleSignupBtnClick}><b>Sign Up</b></button></td>
                            </tr>
                        </table>
                    </nav>
                </div>
            </header>


             <section id="section_head">


                <div id="main_psa">Cura</div><br></br>
                <br></br>
                <br></br>
                <br></br>
                <div id="main_psa" style={{ color: '#FFFFFF', textAlign: 'center', fontSize: '55px' }} >A HIPAA-compliant AI assistant<br></br>
                    for mental health workers.<br></br></div>
                <br></br>
                <h3>Save time. Reduce stress. Be present.</h3><br></br>
                <button class="button_cta button" onClick={handleSignupBtnClick}><b>Sign Up</b></button>


            </section>


            <section id="section_describe">


                <a style={{ fontFamily: 'brandFont' }} >
                    <center>
                        <h2>What is Cura?</h2>
                    </center>
                </a>
                <div id="typical_text">Cura is a HIPAA compliant AI assistant that automates talk therapy notes for mental
                    healthcare workers and makes their jobs a bit easier.<br></br>
                    <br></br>
                    It started as an idea in early 2023, when the spouse of a social worker (LCSW) noticed that his partner was
                    spending 2-3 hours every few Sundays catching up on notes. Aside from delaying them both from watching Netflix, he
                    understood that note-writing was a major burden of unpaid time for therapists, but it was also necessary for
                    insurance payments and to maintain good records for a mental health practice.<br></br>
                    <br></br>
                    Combining recent breakthroughs in generative AI (think: Chat GPT), improvements in voice-to-text transcription,
                    and secure HIPAA-compliant infrastructure, Cura was born. Today, it acts as a specialized application for mental
                    healthcare workers to drastically speed up their note-writing workflows, freeing up their time and empowering them
                    to focus more on their clients, their practice, and their own well-being.
                </div>


            </section>

            <br></br>
            <br></br>
            <footer>


                <table  >
                    <tr><th></th></tr>
                    <tr><th></th></tr>
                    <tr>
                        <th></th>
                    </tr>
                    <tr>
                        <td style={{width: '50%'}}>
                            <h4 style={{textAlign: 'center'}}>Contact</h4>
                        </td>
                        <td>
                            <h4 style={{textAlign: 'center'}}>Follow</h4>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign:'center' }}><a style={{ color: '#FFFFFF' }}
                            href="mailto:hello@curanote.co">&#9993;&nbsp;hello@curanote.co</a>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        <td style={{ textAlign:'center' }}><a style={{ color: '#FFFFFF' }} href="https://whatsapp.com/channel/0029VaNpJSp0G0Xkj4p9i61i"><img
                            src="whatsapp-icon.png" alt="WhatsApp" style={{width: '22px', height: '22px'}} ></img>&nbsp;WhatsApp</a></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: 'center'}}><a style={{ color: '#FFFFFF',textAlign:'center' }} href="tel:1-646-494-2673">&#9743;&nbsp;1-646-494-2673</a></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                
                
                <br></br>
                © 2024 Cura & Byway LLC


            </footer>


        </>
    )
}
export default LandingPage;