import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import * as apiUtils from '../../utils/apiUtils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css'

const ResetPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const pathParams = useParams();

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setLoading(true);

        try {
            const { userID, token } = pathParams;
            const response = await apiUtils.post('/api/auth/password/reset/confirm/', {
                new_password1: newPassword,
                new_password2: confirmPassword,
                uid: userID,
                token: token
            });
            toast.success(response?.detail);
            setSuccess(true);
            navigate('/login')
        } catch (error) {
            setError('Error resetting password');
        }

        setLoading(false);
    };

    return (
        <div className='reset-password-page'>
            <h2>Reset Password</h2>
            {loading && <Loader />} {/* Show loader when loading */}
            {error && <p style={{color: 'red'}}>{error}</p>}
            {success && <p>Password reset successfully!</p>}
            {!success && !loading && (
                <form onSubmit={handleResetPassword}>
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button className='resetButton' type="submit">Reset Password</button>
                </form>
            )}
        </div>
    );
};

export default ResetPassword;
